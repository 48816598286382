import { Component, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getVisibleTasksWithLists, getEnabledLists } from 'reducers/tasks';
import {
  // Card, CardActions, CardHeader, CardMedia, CardTitle, CardText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Grid,
  Box,
  Alert,
  AlertTitle,
} from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSyncOutlined';
import InsightsIcon from '@mui/icons-material/InsightsOutlined';
import SupportIcon from '@mui/icons-material/ContactSupportOutlined';
import StarIcon from '@mui/icons-material/Star';
import XIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { Notification } from './components/NotificationsDock.jsx';
import { isPremium } from 'common/utils';
import { activateFreeTrial, cancelSubscription, applyCode } from './api/payment';
import './premium.scss';
import { saveModule } from './api/account';
import moment from 'moment';
import Analytics from './analytics.jsx';
import BackToHomeButton from './components/BackToHomeButton';
import { PaddleCheckout } from './components/PaddleCheckout.jsx';
import { PageBox, PageNav, PageScroll } from './components/PageBox.jsx';
import { saveToUserProfile, useFirebaseReadOnce } from './common/firebase';
import { styled } from '@mui/system';
import { theme } from './theme';
import { premiumModules } from 'shared';
const { confirm } = window;

const MD4 = ({ children }) => (
  <div className="col-xs-12 col-sm-12 col-md-4" style={{ padding: '0 40px' }}>
    {children}
  </div>
);

const RedButton = ({ children, ...props }) => (
  <Button
    variant="text"
    fullWidth
    // className="upgrade-module-pricing"
    sx={{ color: 'red', backgroundColor: 'white', '&:hover': { backgroundColor: '#eee' } }}
    {...props}
  >
    {children}
  </Button>
);

const DeepWorkBox = styled(Box)(({ theme, background, color }) => ({
  margin: '12px 0',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  boxShadow: 'inset 0px 2px 6px rgba(0,0,0,0.25)',
  color: color || 'initial',
  background: background || theme.palette.boxBackground,
  ['h2']: {
    margin: 0,
    fontWeight: 300,
    fontSize: '1.3rem',
  },
}));

const PrimaryButton = ({ children, ...props }) => (
  <Button
    variant="contained"
    // color="primary"
    fullWidth
    // className="upgrade-module-pricing"
    {...props}
    sx={{
      textTransform: 'none',
      fontSize: '0.95em',
      color: 'black !important', // !important overrides buttons with hrefs
      background: (theme) => theme.palette.gradient.tealBlue,
      borderRadius: '26px',
      '&:hover': [{ boxShadow: '0px 0px 25px rgba(255,255,255,0.25)' }],
    }}
  >
    {children}
  </Button>
);

const LinkButton = ({ children, ...props }) => (
  <Button
    variant="contained"
    fullWidth
    className="premium-gradient"
    sx={{
      textTransform: 'none',
      fontSize: '0.95em',
      color: 'rgb(135,220,230) !important',
      border: '2px solid rgb(135,220,230)',
      borderRadius: '26px',
      '&:hover': [{ boxShadow: '0px 0px 25px rgba(255,255,255,0.25)' }],
    }}
    {...props}
  >
    {children}
  </Button>
);

const SecondaryButton = ({ children, ...props }) => (
  <Button
    variant="contained"
    fullWidth
    // className="upgrade-module-pricing"
    className="premium-gradient upgrade-modal-link"
    sx={{
      textTransform: 'none',
      fontSize: '0.95em',
      color: 'rgb(135,220,230) !important',
      background: 'transparent !important',
      border: '2px solid rgb(135,220,230)',
      borderRadius: '26px',
      '&:hover': [{ boxShadow: '0px 0px 25px rgba(255,255,255,0.25)' }],
    }}
    {...props}
  >
    {children}
  </Button>
);

const CloseButton = ({ onClick }) => (
  <Button
    variant="text"
    fullWidth
    // className="upgrade-modal-link"
    color="info"
    onClick={onClick}
    sx={{
      textTransform: 'none',
      fontSize: '0.95em',
      color: 'rgb(135,220,230) !important',
      borderRadius: '26px',
      background: 'rgba(255,255,255,0.05)',
      '&:hover': [{ boxShadow: 'inset 0px 0px 25px rgba(255,255,255,0.25)' }],
    }}
  >
    Close
  </Button>
);

const PatreonButton = () => (
  <Button
    fullWidth
    href="http://patreon.com/trevor_AI"
    target="_blank"
    sx={{
      textTransform: 'none',
      fontSize: '0.95em',
      color: 'rgb(135,220,230) !important',
      border: '2px solid rgb(135,220,230)',
      backgroundColor: 'transparent !important',
      borderRadius: '26px',
      '&:hover': [{ boxShadow: '0px 0px 25px rgba(255,255,255,0.25)' }],
    }}
    onClick={() => Analytics.event({ category: 'Premium', action: 'Initiated ' + 'Go Pro with Patreon' })}
  >
    Go Pro with Patreon
  </Button>
);

const SpecialCodeButton = ({ onClick }) => (
  <Button
    fullWidth
    className="upgrade-modal-link"
    onClick={onClick}
    sx={{
      textTransform: 'none',
      fontSize: '0.95em',
      color: 'rgb(135,220,230) !important',
      background: 'transparent !important',
      border: '2px solid rgb(135,220,230)',
      borderRadius: '26px',
      '&:hover': [{ boxShadow: '0px 0px 25px rgba(255,255,255,0.25)' }],
    }}
  >
    Apply Special Code
  </Button>
);

const CloseIcon = ({ onClick }) => (
  <XIcon
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '25px',
      right: '25px',
      '&:hover': { cursor: 'pointer', color: 'rgb(80,150,190)' },
    }}
  />
);

export const planTitle = (id) => {
  switch (id) {
    case 'free':
      return 'Free';

    case 'prem':
    case '17268': // sandbox
    case '738217': // paddle
      return 'Pro';

    case 'prem2':
    case '17270': // sandbox
    case '738219': // paddle
      return 'Pro+';

    case 'annprem':
    case '17269': // sandbox
    case '738218': // paddle
      return 'Pro Annual';

    case 'annprem2':
    case '17271': // sandbox
    case '738220': // paddle
      return 'Pro+ Annual';

    case 'patreon':
      return 'Patreon';

    default:
      return 'Early bird';
  }
};

const totalModules = premiumModules.length;

class Premium extends Component {
  state = {
    makePayment: false,
  };

  render() {
    const { user, onActivateFreeTrial, onToggleModule } = this.props;
    let onFreeTrial, onUpgrade, activeUntil, onCancelSubscription;
    let userIsPremium = isPremium(user);
    // userIsPremium = true // TESTING
    const activeModulesObj = user.modules || {};
    const isModuleActive = (module) => !!activeModulesObj[module.id];
    let { activeSubscriptionId } = user;
    // console.log({ activeSubscriptionId, ss: user.subscriptions })
    const expired = moment().isAfter(user.premiumUntil, 'day');

    Analytics.event({
      category: 'Premium',
      action: 'Browsing Upgrade Modules',
    });

    if (userIsPremium) {
      activeUntil = user.premiumUntil;

      if (activeSubscriptionId) {
        onCancelSubscription = async () => {
          try {
            await cancelSubscription(activeSubscriptionId);
            Analytics.event({
              category: 'Premium',
              action: user.premiumPlan + ' subscription Cancelled',
            });
            Notification.show('Subscription cancelled');
          } catch (err) {
            console.log('Error cancelling subscription', err);
            Analytics.event({
              category: 'Premium',
              action: 'Error cancelling subscription',
            });
            Notification.showError('Error cancelling subscription. Please contact us');
          }
        };
      }
    } else {
      if (user.premiumUntil) {
        onUpgrade = () => {
          this.setState({ makePayment: true });
        };
      } else {
        onFreeTrial = async () => {
          try {
            await onActivateFreeTrial();
            Notification.show('Free trial activated');
            console.log('Free trial activated');
          } catch (err) {
            console.log('error activating free trial', err);
            Notification.showError('Cannot activate free trial. Please try again');
          }
        };
      }
    }

    let availableModules = premiumModules.map((module) => {
      return (
        <MD4 key={module.title}>
          {renderUpgradeModule(module, userIsPremium, isModuleActive(module), (active) =>
            onToggleModule(module.id, active)
          )}
        </MD4>
      );
    });

    return (
      <PageBox id="upgrades" sx={{ backgroundColor: 'white' }}>
        <PageNav direction="row" spacing={3}>
          <BackToHomeButton />
          <h2>
            {!expired ? 'Active plan' : 'Expired plan'}: <strong>{planTitle(user.premiumPlan)}</strong>
          </h2>
          <PremiumButton
            link
            label={
              user.premiumPlan && user.premiumPlan != 'free'
                ? expired
                  ? 'Renew Plan'
                  : 'Manage Plan'
                : 'Upgrade to Pro'
            }
          />
        </PageNav>
        <PageScroll>
          <h1 style={{ textAlign: 'center' }}>Available Upgrades for your Trevor</h1>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            {!userIsPremium && (
              <>
                <p style={{ textAlign: 'center', display: 'inline' }}>Enable all available upgrades with a </p>
                <PremiumButton label="Pro plan" link />
              </>
            )}
          </div>
          <hr style={{ borderBottom: '1px solid rgb(230,230,230)' }} />
          <div className="row">{availableModules}</div>
          {/* <hr style={{ borderBottom: '1px solid rgb(230,230,230)' }} />
                    <h1 style={{ textAlign: 'center' }}>R&D Lab<br/>(under construction)</h1>
                    <p style={{ textAlign: 'center' }}>Vote for upgrade modules from our development roadmap or submit ideas as a Premium user to influence the future of Trevor AI.</p>
                    <br/>
                    <br/> */}
          {/* <UpgradeModal module={modules[0]} isPremium={userIsPremium} isModuleActive={isModuleActive(modules[0])} onToggle={active => onToggleModule(modules[0].id, active)}/> */}
        </PageScroll>
      </PageBox>
    );
  }
}

const DiscountCode = ({ onClose, onApply }) => {
  const [code, setCode] = useState('');
  const [status, setStatus] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('pending');
    onApply(code)
      .then(() => onClose())
      .catch((err) => setStatus(err === 'Precondition Failed' ? 'Invalid code' : 'Code already applied'));
  };
  const isPending = status === 'pending';
  const error = !isPending && status;
  return (
    <div style={{ maxWidth: '520px', margin: '0 auto' }}>
      <h1 className="premium-gradient" style={{ textAlign: 'center' }}>
        Apply Special Code
      </h1>
      <CloseIcon onClick={onClose} />
      <form onSubmit={handleSubmit}>
        <TextField
          autoFocus
          fullWidth
          autoComplete="off"
          id="custom-reminder-field"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Special code"
          required
          disabled={isPending}
        />
        {!!error && <div style={{ color: 'red', padding: '10px 0' }}>{error}</div>}
        <div style={{ height: 10 }} />
        <PrimaryButton type="submit" disabled={isPending}>
          Apply
        </PrimaryButton>
      </form>
      <br />
      <CloseButton onClick={onClose} />
    </div>
  );
};

const defaultPlans = {
  prem: { price: 6, period: 'mo', discountPrice: null, discountStr: null },
  prem2: { price: 9.99, period: 'mo', discountPrice: null, discountStr: null },
  annprem: { price: 48, period: 'year', discountPrice: null, discountStr: null },
  annprem2: { price: 99, period: 'year', discountPrice: null, discountStr: null },
};

export const planPrice = (planId, storedPrice = null, plans = defaultPlans, annualCostPerMonth = false) => {
  let plan = plans?.[planId] || defaultPlans.prem;
  let price = storedPrice || plan.discountPrice || plan.price;

  let discountSuffix = '';
  if (!storedPrice && plan.discountPrice && plan.discountStr) {
    discountSuffix = ' ' + plan.discountStr;
  }
  let period = plan.period;

  // annualCostPerMonth is used to return $4/mo instead of $48/year.
  if (annualCostPerMonth && period === 'year') {
    price = price / 12;
    period = 'mo';
  }
  let priceStr = price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
  return `${priceStr}/${period}${period == 'mo' ? '.' : ''}` + discountSuffix;
};

export const PremiumModal = connect((state) => {
  const { user = {} } = state.account;
  const {
    activeSubscription,
    activeSubscriptionId,
    isPremium,
    premiumUntil,
    premiumPlan,
    premiumPrice,
    isFreeTrial,
    subscriptionPendingProcessing,
  } = user;
  const onCancelSubscription = async () => {
    try {
      await cancelSubscription(activeSubscriptionId);
      Analytics.event({
        category: 'Premium',
        action: premiumPlan + ' subscription Cancelled',
      });
      Notification.show('Subscription cancelled');
    } catch (err) {
      console.log('Error cancelling subscription', err);
      Analytics.event({
        category: 'Premium',
        action: 'Error cancelling subscription',
      });
      Notification.showError('Error cancelling subscription. Please contact us');
    }
  };
  const onApplyCode = async (code) => applyCode(code);

  return {
    isPremium,
    isFreeTrial,
    premiumUntil,
    premiumPlan,
    premiumPrice,
    activeSubscription,
    activeSubscriptionId,
    onCancelSubscription,
    onApplyCode,
    user, // Premium Intelligence below
    subscriptionPendingProcessing,
    lists: getEnabledLists(state).length || Object.values(state.tasks.listsObj).length,
    totalTasks: getVisibleTasksWithLists(state).length,
    completedTasks: getVisibleTasksWithLists(state).filter((task) => task.completed).length,
    scheduledTasks: getVisibleTasksWithLists(state).filter((task) => task.eventBeginDate).length,
    // events: getVisibleEvents(state).length,
    // calendars: ObjectValues(state.calendar.calendarsObj).length,
  };
})((props) => {
  const {
    open = false,
    onClose,
    isPremium,
    activeSubscription,
    activeSubscriptionId,
    isFreeTrial,
    onCancelSubscription,
    onApplyCode,
    subscriptionPendingProcessing,
    user,
    fullWidth,
    style,
    label,
    link,
    premiumUntil,
    premiumPlan,
    premiumPrice,
    requiredModule,
  } = props;

  const [specialPrice, setSpecialPrice] = useState(null);

  const { data: plansData, loading } = useFirebaseReadOnce('config-public/plans');
  const plansInfo = plansData || defaultPlans;
  // console.log('TEST ', plansData);
  // console.log('TEST ', plansInfo);
  // console.log('TEST Sub Status: ', activeSubscription);
  const plans = {
    prem: 'Go Pro for ' + planPrice('prem', specialPrice ?? null, plansInfo),
    prem2: 'Go Pro for ' + planPrice('prem', specialPrice ?? null, plansInfo) + ' billed monthly',
    annprem: 'Go Pro for ' + planPrice('annprem', specialPrice ?? null, plansInfo),
    annprem2: 'Go Pro for ' + planPrice('annprem', specialPrice ?? null, plansInfo, true) + ' billed annually',
    premAlt: 'Start a 7-Day Free Trial with a Monthly plan for ' + planPrice('prem', specialPrice ?? null, plansInfo),
    annpremAlt:
      'Start a 7-Day Free Trial with an Yearly plan (' +
      planPrice('annprem', specialPrice ?? null, plansInfo, true) +
      ')',
  };
  const heading = (text) => (
    <h1 className="premium-gradient" style={{ textAlign: 'center', fontWeight: 900, marginBottom: '60px' }}>
      {text}
    </h1>
  );
  let premiumSubtext = (
    <p style={{ margin: '24px', textAlign: 'center', opacity: '0.66' }}>
      7 day free trial included. 30-day money back guarantee. Cancel anytime.
    </p>
  );

  const [selectedPlan, setSelectedPlan] = useState(null);
  // const subscriptionPendingProcessing = window.sessionStorage.getItem('subscriptionPendingProcessing') === 'true';
  const [enterDiscountCode, setEnterDiscountCode] = useState(false);

  let content;
  const datePretty = moment(premiumUntil).format('LL');

  let remainingSub;
  if (activeSubscriptionId) {
    remainingSub = isFreeTrial
      ? 'Trial is active and first payment will be on '
      : 'Plan is active and will auto-renew on ';
    remainingSub +=
      datePretty + ' for ' + planPrice(premiumPlan, activeSubscription?.paddle?.next_payment_amount ?? premiumPrice);
  } else {
    if (premiumPlan === 'patreon') {
      remainingSub = '';
    } else {
      remainingSub = 'Plan is cancelled and will expire on ' + datePretty;
    }
  }

  let renderModulesGrid = (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 340px)',
        gridGap: '25px 15px',
        justifyContent: 'space-evenly',
        padding: '50px 15px',
      }}
    >
      {premiumModules.map((module) => {
        return renderDarkModule(module);
      })}
    </Box>
  );

  let activePlanContent = (
    <>
      {heading(planTitle(premiumPlan) + ' plan is Active')}
      <CloseIcon onClick={onClose} />
      <img
        className="animated tada"
        src="/images/premium_success.svg"
        alt="success"
        style={{
          display: 'block',
          minHeight: '261px',
          width: '250px',
          margin: '10px auto',
          opacity: '0.7',
          filter: 'invert(1)',
        }}
      />
      <br />
      <p style={{ textAlign: 'center' }}>
        Thank you for subscribing! Your support enables us to make Trevor AI smarter and more accessible.
      </p>
      <p style={{ textAlign: 'center' }}>
        Trevor AI's most powerful features will continuously adapt to your individual needs.
      </p>
      <p style={{ textAlign: 'center', opacity: 0.6 }}>Manage your subscription below.</p>
      {renderModulesGrid}
      <p style={{ textAlign: 'center' }}>{remainingSub}</p>
      {activeSubscriptionId && (
        <>
          <Button
            variant="text"
            onClick={() => {
              Analytics.event({ category: 'Premium', action: 'Clicked Cancel Plan Button' });
              if (
                confirm(
                  'Are you sure you want to cancel your subscription? If you are experiencing issues please contact us at support@trevorlabs.com. Your plan will remain active until ' +
                    datePretty
                )
              ) {
                onCancelSubscription();
              }
            }}
            fullWidth
            className="upgrade-module-pricing"
          >
            <span style={{ color: 'red' }}>Cancel Plan</span>
          </Button>
          <hr style={{ margin: '25px 0' }} />
        </>
      )}
      <CloseButton onClick={onClose} />
      <p className="hidden-mobile" style={{ textAlign: 'center' }}>{`Have any questions? Message us --->`}</p>
    </>
  );

  let proPlanContent = (
    <>
      {requiredModule && (
        <Alert
          sx={{
            margin: '0 auto 40px auto',
            maxWidth: '600px',
            background: 'rgba(255,255,255,0.1)',
            color: 'white',
            borderRadius: '14px',
          }}
          severity="info"
        >
          <AlertTitle>{requiredModule.title} is a Pro feature. Start your Free Trial now.</AlertTitle>
          {requiredModule.description}
        </Alert>
      )}
      {heading('The Assistant for Ambitious Planners')}
      <CloseIcon onClick={onClose} />
      <h2 style={{ textAlign: 'center', fontWeight: 300, marginBottom: '30px', fontSize: '1.3em' }}>
        The average professional spends just <strong>2.8 hours on productive task work</strong> per day{' '}
        <a href="https://blog.rescuetime.com/work-life-balance-study-2019/" target="_blank">
          (source)
        </a>
        .
        <br />
        <br />
        <strong>Double the results</strong> of your effort with Trevor's Intelligent Time Blocking technology.
      </h2>
      <PrimaryButton
        onClick={() => {
          setSelectedPlan('annprem');
          Analytics.event({ category: 'Premium', action: 'Initiated ' + plans['annprem'] });
        }}
      >
        {plans['annpremAlt']}
      </PrimaryButton>
      <p style={{ margin: '24px', textAlign: 'center', opacity: '0.66' }}>
        You will be charged {planPrice('annprem', specialPrice ?? null, plansInfo)} after the 7 day free trial.
        <br />
        30-day money back guarantee. Cancel anytime.
      </p>
      <Grid container spacing={0}>
        <Grid item sm={12}>
          {renderModulesGrid}
          <Box sx={{ textAlign: 'center' }}>
            <p
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                textAlign: 'left',
                maxWidth: '700px',
                margin: '20px auto 50px auto',
              }}
            >
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <span style={{ paddingLeft: '15px' }}>
                <i>
                  “This app is great for helping me with prioritizing my day and{' '}
                  <strong>scheduling my most important tasks</strong> on my calendar, so that I make sure they get
                  done.”
                </i>
                <br />— Anthony Gauna
              </span>
            </p>
          </Box>
          <Box>
            <PrimaryButton
              onClick={() => {
                setSelectedPlan('annprem');
                Analytics.event({ category: 'Premium', action: 'Initiated ' + plans['annprem'] });
              }}
            >
              {plans['annprem2']}
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setSelectedPlan('prem');
                Analytics.event({ category: 'Premium', action: 'Initiated ' + plans['prem'] });
              }}
            >
              {plans['prem2']}
            </PrimaryButton>
            {premiumSubtext}
            <PatreonButton />
            <SpecialCodeButton
              onClick={(e) => {
                e.preventDefault();
                setEnterDiscountCode(true);
              }}
            />
            <CloseButton onClick={onClose} />
            <hr style={{ margin: '50px 0' }} />
            <p style={{ opacity: 0.6, textAlign: 'center' }}>
              *Please note that the estimated hours of productive task work per day are based on Trevor AI's
              well-informed approximation. Your results may vary.
            </p>
            <p
              className="hidden-mobile"
              style={{ color: (theme) => theme.palette.primary.main, textAlign: 'center' }}
            >{`Have any questions? Message us --->`}</p>
          </Box>
        </Grid>
      </Grid>
    </>
  );

  let earlyBirdContent = (
    <>
      {heading(planTitle(premiumPlan) + ' plan is Active')}
      <CloseIcon onClick={onClose} />
      <img
        className="animated tada"
        src="/images/premium_success.svg"
        alt="success"
        style={{ display: 'block', width: '250px', margin: '10px auto', opacity: '0.7', filter: 'invert(1)' }}
      />
      <p style={{ textAlign: 'center' }}>
        Thank you for being one of our early supporters.
        <br />
        <strong>All Pro features are enabled for your account.</strong>
      </p>
      {renderModulesGrid}
      <br />
      <PatreonButton />
      <SpecialCodeButton
        onClick={(e) => {
          e.preventDefault();
          setEnterDiscountCode(true);
        }}
      />
      <p style={{ fontSize: '0.9em' }}>
        Trevor AI is developed by co-founders{' '}
        <a href="https://www.linkedin.com/in/george-petrov-a5b25147/" target="_blank">
          George Petrov
        </a>{' '}
        and{' '}
        <a href="https://www.linkedin.com/in/dmitryyudakov/" target="_blank">
          Dmitry Yudakov
        </a>
        , who started work on Trevor's web app on 10 Aug, 2016. Ever since, the co-founders have commited tens of
        thousands lines of code, with the purpose of helping you manage your day in a smarter way.
        <br />
        <br />
        Needless to say, two people couldn't have done it alone - they had Trevor and supporters like you.
      </p>
      <CloseButton onClick={onClose} />
    </>
  );

  let pastDueContent = (
    <>
      {heading('Your ' + planTitle(premiumPlan) + ' plan payment is Past Due')}
      <CloseIcon onClick={onClose} />
      <Alert
        sx={{
          margin: '0 auto 40px auto',
          maxWidth: '600px',
          background: 'rgba(255,255,255,0.1)',
          color: 'white',
          borderRadius: '14px',
        }}
        severity="warning"
      >
        <AlertTitle>
          Oops! It looks like there was a hiccup with your latest payment. No worries, though — there's enough time to
          sort it out. Just update your payment details within 7 days of the unsuccessful payment, and you'll be all set
          to continue enjoying all the Premium perks. We're here to help if you need us!
        </AlertTitle>
        <br />
        <PrimaryButton
          href={
            activeSubscription?.paddle?.update_url ||
            'mailto:support@trevorlabs.com?subject=Update%20Payment%20Link%20Not%20Working'
          }
          target="_blank"
        >
          Update Payment Method
        </PrimaryButton>
        <LinkButton href="mailto:support@trevorlabs.com?subject=Payment%20Issue">Contact Support</LinkButton>
        {activeSubscriptionId && (
          <>
            <Button
              variant="text"
              onClick={() => {
                Analytics.event({ category: 'Premium', action: 'Clicked Cancel Plan Button' });
                if (
                  confirm(
                    'Are you sure you want to cancel your subscription? If you are experiencing issues please contact us at support@trevorlabs.com. Your plan will remain active until ' +
                      datePretty
                  )
                ) {
                  onCancelSubscription();
                }
              }}
              fullWidth
              className="upgrade-module-pricing"
              sx={{ textTransform: 'none' }}
            >
              <span style={{ color: 'red' }}>Cancel Plan</span>
            </Button>
          </>
        )}
      </Alert>

      <h2 style={{ textAlign: 'center', fontWeight: 300, marginBottom: '30px', fontSize: '1.3em' }}>
        Resume your Pro plan to continue enjoying all Premium features:
      </h2>
      {renderModulesGrid}
    </>
  );

  let discountCodeContent = <DiscountCode onApply={onApplyCode} onClose={() => setEnterDiscountCode(false)} />;

  const inProgressContent = (
    <>
      <p style={{ textAlign: 'center' }}>Just few more moments to prepare the subscription...</p>
      <CloseButton onClick={onClose} />
      <CloseIcon onClick={onClose} />
    </>
  );

  if (subscriptionPendingProcessing && !isPremium) {
    content = inProgressContent;
  } else if (enterDiscountCode) {
    content = discountCodeContent;
  } else if (activeSubscription?.status == 'PastDue') {
    // Failed Payment after trial - Paddle might convert to Cancelled automatically in 7 days - check settings. User will receive 3-4 reminder emails from Paddle during that time.
    content = pastDueContent;
  } else if (isPremium && premiumPlan) {
    // Paid user with active timer and plan
    content = activePlanContent;
  } else if (isPremium && !premiumPlan) {
    // early-bird
    content = earlyBirdContent;
  } else if (!isPremium && premiumPlan) {
    // expired
    content = proPlanContent;
  } else {
    content = proPlanContent;
  }

  console.log('dev PLAN: ', selectedPlan);

  return (
    <Dialog
      className="upgrade-modal"
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      sx={{
        ['.MuiDialog-paper']: {
          maxWidth: 'initial',
          width: '100%',
          margin: '56px 5px 5px 5px',
          maxHeight: '100%',
          height: 'calc(100% - 61px)',
          background: 'rgba(0,0,0,0.8)',
          color: 'white',
          backdropFilter: 'blur(5px)',
          borderRadius: '28px',
        },
      }}
    >
      <DialogContent
        className="upgrade-modal-content"
        sx={{
          padding: '48px 10% 20px 10% !important', // overrides theme
          // padding 0 for mobile
          ['@media (max-width: 1050px)']: {
            padding: '30px 10px 10px 10px  !important',
          },
          ['& button, a.MuiButton-root']: {
            display: 'flex', // center
            maxWidth: '520px',
            height: '52px',
            margin: '10px auto',
          },
        }}
      >
        {!selectedPlan && content}
        <PaddleCheckout
          open={!!selectedPlan}
          plan={selectedPlan}
          title={plans[selectedPlan]}
          // onSubcriptionCreated={() => window.sessionStorage.setItem('subscriptionPendingProcessing', 'true')}
          onSubcriptionCreated={() => saveToUserProfile(user.uid, { subscriptionPendingProcessing: true })}
          onSpecialPrice={(price) => {
            console.log('Apply special price', price);
            setSpecialPrice(price);
          }}
          onClose={() => {
            setSelectedPlan(null);
            setSpecialPrice(null);
          }}
          data={props}
        />
      </DialogContent>
    </Dialog>
    // </div>
  );
});

let g_instPremiumModalDock = null;
const MODAL = '__MODAL__';
export class PremiumModalDock extends Component {
  state = {
    open: null,
    onClose: null,
  };
  componentDidMount() {
    g_instPremiumModalDock = this;
  }
  static showPlans() {
    g_instPremiumModalDock.setState({
      open: MODAL,
    });
  }
  static showUpgradeModule(moduleId, onClose = null) {
    Analytics.event({
      category: 'Premium',
      action: 'Show Upgrade Required: ' + moduleId,
    });
    g_instPremiumModalDock.setState({
      open: moduleId,
      onClose,
    });
  }
  render() {
    const { open } = this.state;
    const onClose = () => {
      const _onClose = this.state.onClose;
      this.setState({ open: null, onClose: null });
      _onClose && _onClose();
    };
    if (open === MODAL) return <PremiumModal open onClose={onClose} />;
    if (open) return <PremiumModal open requiredModule={premiumModules.find((m) => m.id === open)} onClose={onClose} />; // Shows premium modal with 'module required' alert
    // if (open) return <UpgradeModal open module={modules.find((m) => m.id === open)} onClose={onClose} />; // Legacy
    return <span />;
  }
}

export const PremiumButton = ({ fullWidth = false, style, label, link }) => {
  const user = useSelector((state) => state.account.user);
  const expired = moment().isAfter(user.premiumUntil, 'day');
  const defaultLabel =
    user.premiumPlan && user.premiumPlan != 'free'
      ? expired
        ? 'Renew Pro Plan'
        : 'Pro Plan Active'
      : 'Upgrade to Pro';

  const handleOpen = (e) => {
    e.preventDefault();
    Analytics.event({ category: 'Premium', action: 'Opened Premium Modal with ' + (label || defaultLabel) });
    PremiumModalDock.showPlans();
  };

  let openbutton = (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      color="primary"
      onClick={handleOpen}
      // sx={{ padding: '0 10px 0 10px', color: 'white' }}
      style={style}
    >
      {/* <span style={{ color: 'white' }}> */}
      {label || defaultLabel || 'Open Pro Plan'}
      {/* </span> */}
    </Button>
  );
  if (link) {
    openbutton = (
      <a
        // style={style}
        onClick={handleOpen}
        href=""
      >
        {label || defaultLabel || 'Open Pro Plan'}
      </a>
    );
  }

  return <div style={{ display: 'inline-block', width: fullWidth ? '100%' : '' }}>{openbutton}</div>;
};

const UpgradeButtons = (module, isPremium, isActive, onToggle) => {
  if (isActive) {
    // console.log("Module Active")
    const deactivate = () => onToggle(false);
    if (isPremium) {
      // console.log("Module Premium User")
      return (
        <>
          <SecondaryButton disabled>Module is enabled (Pro)</SecondaryButton>
          {!module.isPremium && <RedButton onClick={deactivate}>Disable Module</RedButton>}
        </>
      );
      // if(module.price) {
      //     console.log("Module Priced")
      //     return (<><PrimaryButton>Module is enabled (Pro)</PrimaryButton>
      //             <RedButton onClick={deactivate}>Disable Module</RedButton></>)
      // } else { // !module.price
      //     console.log("Module Free")
      //     return (<><PrimaryButton disabled>Module is enabled (Pro)</PrimaryButton>
      //             <RedButton onClick={deactivate}>Disable Module</RedButton></>)
      // }
    } else {
      // !isPremium
      // console.log("Module Not-Premium User")
      return (
        <>
          <SecondaryButton disabled>Module is enabled</SecondaryButton>
          <RedButton onClick={deactivate}>Disable Module</RedButton>
        </>
      ); // isPremium modules get auto-disabled
      // // if(module.price) {
      //     console.log("Module Priced")
      //     return (<>
      //         <PrimaryButton disabled>Module is enabled for {modulePriceLabel(module)}</PrimaryButton>
      //         <RedButton onClick={deactivate}>Disable Module</RedButton>
      //     </>)
      // } else { // !module.price
      //     console.log("Module Free")
      //     return <>
      //         <PrimaryButton disabled>Module is enabled and free to use</PrimaryButton>
      //         <RedButton onClick={deactivate}>Disable Module</RedButton>
      //     </>
      // }
    }
  } else {
    // console.log("Module Inactive")
    const activate = () => onToggle(true);
    if (isPremium) {
      // console.log("Module Premium User")
      return <PrimaryButton onClick={activate}>Enable Module (Pro)</PrimaryButton>;
      // if(module.price) {
      //     console.log("Module Priced")
      //     return <SecondaryButton onClick={activate}>Enable Module (Pro)</SecondaryButton>
      // } else { // !module.price
      //     console.log("Module Free")
      //     return <SecondaryButton onClick={activate}>Enable Module (Pro)</SecondaryButton>
      // }
    } else {
      // !isPremium
      // console.log("Module Not-Premium User")
      return (
        <PremiumButton
          fullWidth
          label="Enable with a Pro Plan"
          // style={{color: 'white'}}
        />
      );
      // if(module.tier === 1) {
      //     console.log("Module has tier")
      //     return (<div>
      //         {/* <SecondaryButton>Upgrade for {modulePriceLabel(module)}</SecondaryButton> */}
      //         <PremiumButton fullWidth label="Upgrade with Pro"/></div>)
      // }
      // if(module.price) {
      //     console.log("Module Priced")
      //     return (<div>
      //         <SecondaryButton>Upgrade for {modulePriceLabel(module)}</SecondaryButton>
      //         <PremiumButton fullWidth label="Upgrade with Pro"/></div>)
      // } else { // !module.price
      //     console.log("Module Free")
      //     return <SecondaryButton onClick={activate}>Enable Module for free</SecondaryButton>
      // }
    }
  }
};

const UpgradeModal = connect(
  (state) => {
    let user = state.account.user;
    return {
      user,
    };
  },
  () => {
    const onToggleModule = async (id, active) => {
      try {
        Analytics.event({
          category: 'Premium',
          action: id + ' module manually ' + (active ? 'enabled' : 'disabled'),
        });
        await saveModule(id, active);
      } catch (e) {
        console.log('Error saving module', e);
        Analytics.event({
          category: 'Premium',
          action: 'Error saving ' + id + ' module',
        });
      }
    };
    return {
      onToggleModule,
    };
  }
)(({ open, isPremium, module, isModuleActive, onToggle, onClose, onToggleModule, user }) => {
  // Modal used for detailed view of each Upgrade Module as well as a prompt to upgrade when functionality is locked.
  if (isPremium === undefined) {
    isPremium = user.isPremium;
  }

  if (isModuleActive === undefined) {
    let activeModulesObj = user.modules || {};
    isModuleActive = !!activeModulesObj[module.id];
  }

  let closeButton = (
    <Button variant="text" fullWidth onClick={onClose} sx={{ color: 'rgb(135,220,230) !important' }}>
      Close
    </Button>
  );
  if (module.id === 'progress' && isModuleActive === false) {
    closeButton = (
      <Link to="/app/">
        <Button fullWidth onClick={onClose}>
          Go Back
        </Button>
      </Link>
    );
    onClose = null; // Prevents close onBackdropClick
  }

  if (onToggle === undefined) {
    onToggle = (active) => onToggleModule(module.id, active);
  }

  return (
    <Dialog className="upgrade-modal" open={open} onClose={onClose} scroll="paper">
      <DialogTitle>Upgrade your Trevor</DialogTitle>
      <DialogContent sx={{ maxWidth: '500px' }}>
        {renderUpgradeModule(module, isPremium, isModuleActive, onToggle)}
        {/* <p style={{ textAlign: 'center' }}>
          <strong>7 day free trial included.</strong>
        </p> */}
        <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
          {/* <Button
            variant="text"
            fullWidth
            // className="upgrade-modal-link"
            component={Link}
            to="/app/upgrades"
            onClick={() => onClose()}
          >
            Browse all Upgrade Modules
          </Button> */}
          {closeButton}
        </Stack>
      </DialogContent>
    </Dialog>
  );
});

export const AccountSettingsComponent = connect((state) => ({
  user: state.account.user,
}))(({ user }) => {
  const { modules } = user; // only active modules stored in Redux
  return (
    <>
      <h3>
        Enabled Trevor Upgrades: {Object.keys(modules).length} / {totalModules}{' '}
      </h3>
      <p>Empower your Trevor with upgrades that improve your planning and boost your productivity</p>
      <Button
        variant="outlined"
        fullWidth
        // className="upgrade-modal-link"
        component={Link}
        to="/app/upgrades"
      >
        Browse Upgrade Modules
      </Button>
    </>
  );
});

const moduleImageStyle = (module) => {
  return {
    backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `)`,
    backgroundSize: module.imageSize ? module.imageSize : '80px',
    backgroundPosition: module.imagePosition ? module.imagePosition : 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `_hover.svg)`, // Not supported by react yet.
    },
  };
};

const moduleImageDarkStyle = (module) => {
  return {
    minWidth: '60px',
    // height: '80px',
    alignItems: 'center',
    backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `)`,
    backgroundSize: module.imageSize ? module.imageSize : '60px',
    backgroundPosition: module.imagePosition ? module.imagePosition : 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `_hover.svg)`, // Not supported by react yet.
    },
    filter: 'invert(1)',
  };
};

const renderDarkModule = (module, isActive = false) => {
  return (
    <Box key={module.title} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <h3 style={{ textAlign: 'center', fontWeight: 300, color: 'rgb(135,220,230)' }}>{module.benefit}</h3>
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: '-1px -1px 0 rgba(80,210,255,0.75), 1px 1px 0 rgba(130,230,200,0.75)',
          background:
            'linear-gradient(113deg, rgba(255, 255, 255, 0.17) -2.28%, rgba(255, 255, 255, 0.20) 51.75%, rgba(255, 255, 255, 0.00) 105.78%, rgba(255, 255, 255, 0.17) 105.78%)',
          padding: '15px 15px 15px 20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
          flex: 1,
          transition: 'box-shadow 0.15s ease',
          '&:hover': { boxShadow: '0px 0px 15px rgba(135,220,230,0.75)' },
        }}
      >
        <Box style={moduleImageDarkStyle(module)} alt={module.title}></Box>
        <Box>
          <h4 style={{ fontSize: '17px', fontWeight: 400, margin: 0 }}>{module.title}</h4>
          <p style={{ color: '#B9D1D8', fontSize: '14px', fontWeight: 400, margin: '1em 0 0 0' }}>
            {module.description}
          </p>
        </Box>
      </Box>
    </Box>
  );
};

const renderPassiveModule = (module, isActive = false) => {
  return (
    <div key={module.title}>
      <div className="upgrade-modal-module upgrade-modal-passive">
        <div className={'module-socket' + (isActive ? ' module-active' : ' module-demo')}>
          <div className="module-chip-container">
            <div className="module-chip">
              <div className="module-icon" style={moduleImageStyle(module)} alt={module.title}></div>
              <div className="module-title">{module.title}</div>
            </div>
            <div className="module-chip-back">
              <div className="module-chip-description">{module.description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderUpgradeModule = (module, isPremium, isActive, onToggle) => {
  return (
    <div>
      <div className="upgrade-modal-module">
        <div className={'module-socket' + (isActive ? ' module-active' : ' module-demo')}>
          <div className="module-chip">
            <div className="module-icon" style={moduleImageStyle(module)} alt={module.title}></div>
            <div className="module-title">{module.title}</div>
          </div>
        </div>
      </div>
      <p>{module.description}</p>
      <br />
      {UpgradeButtons(module, isPremium, isActive, onToggle)}
    </div>
  );
};

export default connect(
  (state) => {
    // console.log('premium')
    let user = state.account.user;
    return {
      user,
    };
  },
  () => {
    const onToggleModule = async (id, active) => {
      try {
        Analytics.event({
          category: 'Premium',
          action: id + ' module manually ' + (active ? 'enabled' : 'disabled'),
        });
        await saveModule(id, active);
      } catch (e) {
        console.log('Error saving module', e);
        Analytics.event({
          category: 'Premium',
          action: 'Error saving ' + id + ' module',
        });
      }
    };
    return {
      onActivateFreeTrial: activateFreeTrial,
      onToggleModule,
    };
  }
)(Premium);
